import React, { useRef, useState } from "react";
import "../../Assets/Styles/Mobile_Styles/ContentsSlideMobile.css";
import backgroundImage from '../../Assets/Mobile/Meet-Our-Patients/image_patients.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from "swiper";
import { Translation } from "react-i18next";
import ReactPlayer from 'react-player'
import VideoModalMobile from '../Modals/VideoModalMobile';
import PlayVideoIcon from "../PlayVideoIcon";
import i18n from "i18next";

const TheAmazingMobile = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [urlVideos, setUrlVideos] = useState();
    const lng = i18n.language;

    const AmazingData = [
        {
            image: require("../../Assets/Mobile/Meet-Our-Patients/image-strokes-krissy.png"),
            name: "Benjamin",
            country: "Kentucky",
            caption: "“Every day I wake up, I'm going to fight as needed so I can keep making a difference in everything I'm doing. And if I can make a difference and make the world a better place, then I'll do that in any capacity I can every morning I wake up.”",
            URL: "Watch Benjamin’s story",
            nameURL: lng === 'en' ? "fEo8987Xd7Y" : "U7TYMtyoOJM"
        },
        {
            image: require("../../Assets/Mobile/Meet-Our-Patients/fill-nicholas.png"),
            name: "Megan",
            country: "Michigan",
            caption: "“Normal is a really relative term and I think sometimes we put that word normal, and we should put that aside because what life is like from a normal for one person is different from the next.”",
            URL: "Watch Megan’s story",
            nameURL: lng === 'en' ? "p6tpnYGhrE8" : "lOYnsWo1h8s"
        },
        {
            image: require("../../Assets/Mobile/Meet-Our-Patients/image-two-strokes-Alice.png"),
            name: "Alice",
            country: "Michigan",
            caption: "“Because of plasma donations, I'm alive. The disease came as a surprise to me was when I was 54 years old. The name of the disease is called Common Variable Immune Deficiency”",
            URL: "Watch Alice’s story",
            nameURL: lng === 'en' ? "p7awdHRjW18" : "nFiD-botMJM"
        }
    ]
    
    const HandleModel = (url) => {
        setUrlVideos(url);
        setModalOpen(!modalOpen);
    }
    const playerRef = useRef(null);
    
    return (
        <Translation>
            {t => (
                <div className="d-md-none d-block w-100">
                    <div className="amazing_body">
                        <div
                            className="d-flex flex-col align-items-center"
                            style={{
                                paddingTop: "40px",
                                backgroundColor: "#f2f2f1",
                                clipPath: "polygon(0px 0px, 100% 0px, 100% 95%, 0% 100%)",
                                width: '100%',
                                height: '600px',

                            }}
                        >
                            <div className="title_content d-flex flex-col align-items-center text-center">
                                <h2 className="first_title">{t("Together, we can")}<strong className="second_title"><br />{t('DO THE AMAZING')}</strong></h2>
                            </div>
                            <div className="row g-0 justify-content-center text-center">
                                <p className="sub_caption col-10">
                                    {t('Do you rely on plasma derived therapies? Your participation in Plasma P.A.L.S. can help show the real-life impact that both donors and employees have in saving lives through plasma donation.')}
                                </p>
                            </div>
                            <div className='' style={{ margin: "auto", width: "95%", border: "3px solid #FFA814", borderRadius: "5px", display: 'flex', justifyContent: "center", marginTop: "5px" }}>
                                <ReactPlayer width="715px" height="200px" ref={playerRef} url="https://youtu.be/ROgdN4hAqQU" controls={true} />
                            </div>
                        </div>
                        <div
                            className="mx-5 d-flex flex-col align-items-center"
                            style={{ paddingTop: "80px" }}
                        >
                            <div className="amazing_title d-flex flex-col align-items-center text-center">
                                <h2 className="first_title">{t('Meet our')}<strong className="second_title"><br />{t('PATIENTS')}</strong></h2>
                            </div>
                            <div className="row g-0 justify-content-center">
                                <p className="amazing_description col-11">
                                    {t('Plasma is the key ingredient for medicines crucial to treating patients suffering from several life-threatening conditions around the world. Meet some of our patients whose lives have been changed by the life-saving role that all our donors play.')}
                                </p>
                            </div>
                        </div>

                        <div className="amazing_background_image justify-content-center row g-0" style={{
                            backgroundImage: `url(${backgroundImage})`
                        }}>
                            <div className='box_content_amazing glassmorphism col-10'>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    centeredSlides={true}
                                    modules={[Pagination]}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    loop={true}
                                    className="mySwiper"
                                    id="swiper-replace"
                                >
                                    {
                                        AmazingData.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='content_amazing row g-0 justify-content-center'>
                                                    <div className='outline_image_amazing col-10'>
                                                    <PlayVideoIcon marginTopH="70px" position="absolute" marginLeft="15px" HandleModel={() => HandleModel(item.nameURL)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-14px" backgroundColor="black" />
                                                        <img className='image_amazing' alt='' src={item.image} />
                                                    </div>
                                                    <div className='donor_description col-11'>
                                                        <div className="d-flex flex-col align-items-center">
                                                            <div className='donor_users'>
                                                                <h3 className='donor_name'>{item.name}</h3>
                                                            </div>
                                                            <p className='amazing_caption'>
                                                                {t(item.caption)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <a onClick={() => HandleModel(item.nameURL)} className="bootstrap-link" style={{ textDecoration: 'none' }}>
                                                        <p className='donor_link_text'>
                                                            {t(item.URL)}
                                                        </p>
                                                    </a>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="line_gold"></div>
                    <VideoModalMobile
                        setShow={setModalOpen}
                        show={modalOpen}
                        HandleModel={HandleModel}
                        setModalOpen={setModalOpen}
                        urlVideos={urlVideos}
                    />
                </div>
            )}
        </Translation>
    );
};

export default TheAmazingMobile;
