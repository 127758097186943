import React, { useState } from "react";
import { Translation } from "react-i18next";
import { WEB_BASE_URL_LINK } from "../../config/api";
import ButtonMoreStories from "../ButtonMoreStories";
import VideoModal from "../Modals/VideoModal";
import PlayVideoIcon from "../PlayVideoIcon";

const DonorsData = [

    {
        name: "",
        description: "",
        image: "",
        url: "",
        titleURL: ""
    },
    {
        name: "Theresa",
        description: "“I started donating because I wanted to help out my mom, and I continue because I realized it really does help people.”",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Donors/image-strokes1.png"),
        url: "CECtmq0FSbg",
        titleURL: "Watch Theresa’s story"
    },
    {
        name: "Kathy",
        description: "“I started donating plasma because I felt that there is a need for it. It makes me feel great that I can do this for somebody that needs it so much. ”",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Donors/image-jose1.png"),
        url: "ESatTnEu1sw",
        titleURL: "Watch Kathy’s story"
    },
]

const DonorDesktop = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [urlVideos, setUrlVideos] = useState();

    const HandleModel = (url) => {
        setUrlVideos(url);
        setModalOpen(!modalOpen);
    }
    return (
        <Translation>
            {t => (
                <div className='col-md-12 d-md-block d-none' style={{ overflow: 'hidden' }}>
                    <div className='lifeSave' style={{ backgroundColor: '#EDECEA' }}>
                        <div className='' style={{ paddingTop: '80px' }}>
                            <h2>{t('Meet our Lifesavers')}<strong className="donors"><br />{t('DONORS')}</strong></h2>
                            <div className="row">
                                <p style={{ marginTop: '28px' }}>{t('Plasma is an important element of the human body that can make a huge difference for those who need it. Here’s why our donors donate.')}</p>
                            </div>
                        </div>
                        <div className="container" style={{ marginTop: '80px' }}>
                            <div className="row">
                                {
                                    DonorsData.map((item, index) =>
                                        <div key={index} className={index === 0 ? "col-xl-1 col-sm-6" : "col-xl-5 col-sm-6"}>
                                            <div className="rounded py-5 px-4">
                                                <div className='bloked'>
                                                    {item.image ?
                                                        <>
                                                            <img style={{ width: '312px', height: '312px' }} src={item.image} className="img-fluid" alt="..."></img>
                                                            <PlayVideoIcon marginTopH="135px" position="absolute" marginLeft="2px" HandleModel={() => HandleModel(item.url)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-14px" backgroundColor="black" /> </> : ""
                                                    }
                                                    <h3 className="mb-0" >{item.name}
                                                    </h3>
                                                </div>
                                                <div className="col" >
                                                    <div className="row" style={{ height: '15rem' }}>
                                                        <div className='span'>
                                                            <span className="textSpan">{t(item.description)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=''>
                                                            <a onClick={() => HandleModel(item.url)} className="bootstrap-link" style={{ cursor: "pointer" }}>{t(item.titleURL)}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <ButtonMoreStories
                                title={t("MORE DONOR STORIES")}
                                url={WEB_BASE_URL_LINK + '/stories'}
                                name="btn btn-outline-danger btn-circle"
                            />
                        </div>
                    </div>
                    <VideoModal
                        setShow={setModalOpen}
                        show={modalOpen}
                        HandleModel={HandleModel}
                        setModalOpen={setModalOpen}
                        urlVideos={urlVideos}
                    />
                </div>
            )}
        </Translation>
    )
}

export default DonorDesktop