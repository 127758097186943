import React, { useState } from "react";
import "../../Assets/Styles/Mobile_Styles/ContentsSlideMobile.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from "swiper";
import { Translation } from "react-i18next";
import VideoModalMobile from '../Modals/VideoModalMobile';
import PlayVideoIcon from "../PlayVideoIcon";

const EmployeeData = [
    {
        name: "Daniel Amor",
        normalText: "Saving lives is one thing that you take home. It makes you feel good about your job.",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Employees/original-image-Ryan.png"),
        URL: "Watch Daniel’s story",
        nameURL: "pTFnrXNqx2o",
        position: "Assistant Center Manager"
    },
    {
        name: "Michelle Buchanan",
        normalText: "I have a niece who uses a plasma product. We almost lost her before they diagnosed her, and now she takes a plasma-derived product, and it saved her life",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Employees/image-Nathan.png"),
        URL: "Watch Michelle’s story",
        nameURL: "QVGh1d34tYM",
        position: "Learning & Development Manager"
    }
]

const EmployeeSlideMobile = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [urlVideos, setUrlVideos] = useState();

    const HandleModel = (url) => {
        setUrlVideos(url);
        setModalOpen(!modalOpen);
    }
    return (
        <Translation>
            {t => (
                <div
                    className="d-md-none d-block w-100"
                >
                    <div className="mx-5 d-flex flex-col align-items-center" style={{ paddingTop: '50px' }} >
                        <div className="title_content d-flex flex-col align-items-center text-center">
                            <h2 className="first_title">{t('Meet our Lifesavers')}<strong className="second_title"><br />{t('EMPLOYEES')}</strong></h2>
                        </div>
                        <div className="row g-0 justify-content-center">
                            <p className="caption_content_employee col-10">{t("Along with our plasma donors, our dedicated team members provide a valuable gift to those who require plasma-derived therapies to live healthier lives. Here’s their take on the impact of donations.")}</p>
                        </div>
                    </div>

                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        centeredSlides={true}
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                        }}
                        loop={true}
                        className="mySwiper"
                        id="swiper-replace"
                        style={{ marginBottom: '50px' }}
                    >
                        {
                            EmployeeData.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="employee_content mx-5">
                                        <img className="image_content" src={item.image} alt="" />
                                        <div className="text_content row g-0 justify-content-center">
                                            <div className="col-10">
                                            <PlayVideoIcon marginTopH="-140px" position="absolute" marginLeft="120px" HandleModel={() => HandleModel(item.nameURL)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-14px" backgroundColor="black" />
                                                <img className="quote_content" alt="" src={require("../../Assets/Desktop/Meet-Our-Lifesavers-Employees/quote-mark-left.png")} />
                                            </div>
                                            <p className="des_slide text-center col-8">
                                                {t(item.normalText)}
                                            </p>
                                            <div className="col-10">
                                                <img className="quote_content float-end" alt="" src={require("../../Assets/Desktop/Meet-Our-Lifesavers-Employees/quote-mark-right.png")} />
                                            </div>
                                            <p className="title_slide">{item.name}</p>
                                            <span className="employee_span_ligt text-center">{t(item.position)}</span>
                                            <a onClick={() => HandleModel(item.nameURL)} className="bootstrap-link" style={{ textDecoration: 'none' }}>
                                                <p className='donor_link_text'>
                                                    {t(item.URL)}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <VideoModalMobile
                        setShow={setModalOpen}
                        show={modalOpen}
                        HandleModel={HandleModel}
                        setModalOpen={setModalOpen}
                        urlVideos={urlVideos}
                    />
                </div>
            )}
        </Translation>
    );
};

export default EmployeeSlideMobile;
