import React, { Fragment } from 'react'
import DesktopHeader from './DesktopHeader'
import FooterNavigation from './FooterNavigation'
import MobileHeader from './MobileHeader'

const Layout = (props) => {
  return (
    <Fragment>
      <DesktopHeader/>
      <MobileHeader />
      <main>
        {props.children}
      </main>
      <FooterNavigation />
    </Fragment>
  )
}

export default Layout