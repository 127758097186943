import React, { Fragment } from 'react'
import EmployeeSlideMobile from './Mobile/EmployeeSlideMobile'
import EmployeeDesktop from './Desktop/EmployeeDesktop'

const Employee = () => {
  return (
    <Fragment>
      <EmployeeDesktop />
      <EmployeeSlideMobile />
    </Fragment>
  )
}

export default Employee