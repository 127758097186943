import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import API from '../../utils/request'
import { API_POTENTIAL, WEB_BASE_URL_LINK } from '../../config/api'
import '../../Assets/Styles/Desktop_Styles/PotentialDonor.css'
import Alert from 'react-bootstrap/Alert';
import { validateEmail, validateName } from '../../utils/validation'
import Swal from 'sweetalert2'
import { Translation } from 'react-i18next'
import { t } from 'i18next'

const FormModalDesktop = (props) => {
    const { modalOpen, HandleModel, emailValue, handleEmail, setModalOpen, emailValid, setEmailValid } = props
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState()
    const [zipCode, setZipCode] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [checked, setChecked] = useState(false)
    const [show, setShow] = useState(false);
    const [warning, setWarning] = useState("")
    const [variant, setVariant] = useState("danger")
    const [error, setError] = useState({})
    const [email, setEmail] = useState()
    const languages = ['es'];
    let url = window.location.pathname;
    var langs = url.substring(url.lastIndexOf('/') + 1)
    const lang = languages.includes(langs) ? `/${langs}` : '';

    useEffect(() => {
        if (emailValue) {
            setEmail(emailValue)
        }
    }, [emailValue])

    if (lang === '/es') {
        var translate = "Guardar con éxito!"
    }
    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value)
        setError({
            ...error,
            firstName: event.target.value && !validateName(event.target.value) ? t("First name should only include letters or special characters") : event.target.value && event.target.value.toLowerCase() === lastName.toLowerCase() ? t("First name and last name should not be the same") : "",
            lastName: event.target.value && !validateName(event.target.value) ? t("Last name should only include letters or special characters") : event.target.value && event.target.value.toLowerCase() === lastName.toLowerCase() ? t("First name and last name should not be the same") : ""
        })
        return false
    }
    const handleChangeLastName = (event) => {
        setLastName(event.target.value)
        setError({
            ...error,
            firstName: event.target.value && !validateName(event.target.value) ? t("First name should only include letters or special characters") : event.target.value && event.target.value.toLowerCase() === firstName.toLowerCase() ? t("First name and last name should not be the same") : "",
            lastName: event.target.value && !validateName(event.target.value) ? t("Last name should only include letters or special characters") : event.target.value && event.target.value.toLowerCase() === firstName.toLowerCase() ? t("First name and last name should not be the same") : ""
        })
        return false
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        setError({
            ...error,
            email: event.target.value && !validateEmail(event.target.value) ? t("A valid email address is required") : emailValid.includes(event.target.value) ? "This email address is already in use" : ""
        })
        handleEmail(event.target.value)
        return false
    }
    const validateZipCode = (zipCode) => {
        if (zipCode.length <= 5) {
            var isValidZip = /(^\d{5}$)/.test(zipCode.slice(0, 5));
        } else {
            var isValidZip = /\w/g.test(zipCode.slice(0, 7));
        }
        return isValidZip
    }
    const handleChangeZipCode = (event) => {
        let code = event.target.value.replace(/\W+/g, '').replace(/(\w{3})(\w{3})/, '$1-$2').toUpperCase().slice(0, 7);
        setZipCode(code)
        setError({
            ...error,
            zipCode: code && !validateZipCode(code) ? t("ZIP code should be 5 digits") : ""
        })
        return false
    }
    const validateNumber = (number) => {
        var isValidNumber = /(^\d{10}$)|(^\d{10}-\d{10}$)/.test(number);
        return isValidNumber
    }
    const handleChangePhone = (event) => {
        let phone = event.target.value.replace(/[^0-9]/g, "").slice(0, 10)
        setPhoneNumber(phone)
        setError({
            ...error,
            phoneNumber: phone && !validateNumber(phone) ? t("Phone number should be 10 digits") : ""
        })
    }

    const handleSubmitData = () => {
        var dataForm = {
            "firstName": firstName,
            "lastName": lastName,
            "email": !emailValue ? email : emailValue,
            "zipCode": zipCode,
            "phoneNumber": {
                "dailCode": "+1",
                "phone": phoneNumber
            },
            "sourceWebsite": window.location.origin,
            channel: "lead-gen-website"
        }

        if (checked === false) {
            setShow(true);
        } else {
            API.post(API_POTENTIAL, dataForm)
                .then((res) => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: translate ? translate : 'Save Successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    emailValid.push(email)
                    setEmailValid([...emailValid])
                    setFirstName("")
                    setLastName("")
                    setZipCode("")
                    setPhoneNumber("")
                    setError(false)
                    setModalOpen(false)
                    handleEmail("")
                    setEmail("")
                })
                .catch((err) => {
                    if (err.message) {
                        setShow(false)
                        setVariant("danger")
                        setWarning(err.message)
                    }
                    if (err.message === "Email already exists.") {
                        setError({
                            ...error,
                            email: t("This email address is already in use")
                        })
                        emailValid.push(email)
                        setEmailValid([...emailValid])
                    }
                })
        }
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();
        return false;
    }

    return (
        <Translation>
            {t => (
                <Modal show={modalOpen} className="modal fade bd-example-modal-lg desktop">
                    {
                        show ? <Alert variant={variant} onClose={() => setShow(false)} dismissible style={{ marginTop: '10px' }}><p>{warning}</p></Alert> : null
                    }
                    <Modal.Body>
                        <Modal.Header closeButton style={{ borderColor: 'white', marginRight: '-48px', marginTop: '-10px' }} onClick={() => HandleModel()}>
                            <h5 style={{ fontSize: '20px', fontWeight: 800, marginLeft: '-15px' }} className="text-center">{t("Are you ready to learn more?")}</h5>
                        </Modal.Header>
                        <div className="col-md-12">
                            <div className="pt-0" style={{ marginTop: '-20px' }}>
                                <small>
                                    {t("Sign up to receive information about plasma donation and see new donor fees in your area.")}
                                </small>
                            </div>
                            <form className="row g-3 mt-3" onSubmit={handleSubmitForm}>
                                <div className="col-md-6">
                                    <b htmlFor="inputName" className="form-label" style={{ fontSize: '16px', fontWeight: 600 }}>{t("First Name")}</b>
                                    <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                    <input
                                        value={firstName}
                                        id="firstName"
                                        onChange={handleChangeFirstName}
                                        required type="text" className={`form-control ${firstName ? 'is_style_active' : "is_style_inactive"} ${error.firstName ? 'is-invalid' : ''}`} />
                                    {
                                        error.firstName ? <small className='text-danger'>{error.firstName}</small> : <small>{t("First name should only include letters or special characters")}</small>
                                    }
                                </div>
                                <div className="col-md-6">
                                    <b htmlFor="inputLastName" className="form-label" style={{ fontSize: '16px', fontWeight: 600 }}>{t("Last Name")}</b>
                                    <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                    <input
                                        value={lastName}
                                        onChange={handleChangeLastName}
                                        required type="text" className={`form-control ${lastName ? 'is_style_active' : "is_style_inactive"} ${error.lastName ? 'is-invalid' : ''}`} id="inputLastName" />
                                    {
                                        error.lastName ? <small className='text-danger'>{error.lastName}</small> : <small>{t("Last name should only include letters or special characters")}</small>
                                    }
                                </div>
                                <div className="col-md-4" style={{ marginTop: '30px' }}>
                                    <b htmlFor="inputEmail" className="form-label" style={{ fontSize: '16px', fontWeight: 600 }}>{t("Email")}</b>
                                    <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                    <input
                                        value={email}
                                        onChange={handleChangeEmail
                                        } required type="email"
                                        className={`form-control ${email || emailValue ? 'is_style_active' : ""} ${error.email ? 'is-invalid' : ''}`} id="inputEmail" />
                                    {
                                        error.email ? <small className='text-danger'>{error.email}</small> : <small>{t("A valid email address is required")}</small>
                                    }
                                </div>
                                <div className="col-md-4" style={{ marginTop: '30px' }}>
                                    <b htmlFor="inputCode" className="form-label" style={{ fontSize: '16px', fontWeight: 600 }}>{t("ZIP Code")}</b>
                                    <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                    <input
                                        value={zipCode}
                                        onChange={handleChangeZipCode}
                                        onKeyPress="if(this.value.length==7) return false;"
                                        required className={`form-control ${zipCode ? 'is_style_active' : ""} ${error.zipCode ? 'is-invalid' : ''}`} id="inputCode" />
                                    {
                                        error.zipCode ? <small className='text-danger'>{error.zipCode}</small> : <small>{t("ZIP code should be 5 digits")}</small>
                                    }
                                </div>
                                <div className="col-md-4" style={{ marginTop: '30px' }}>
                                    <b htmlFor="inputPhone" className="form-label" style={{ fontSize: '16px', fontWeight: 600 }}>{t("Phone Number")}</b>
                                    <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                    <input
                                        value={phoneNumber}
                                        onChange={handleChangePhone}
                                        required type="number" className={`form-control ${phoneNumber ? 'is_style_active' : ""} ${error.phoneNumber ? 'is-invalid' : ''}`} id="inputPhone" />
                                    {
                                        error.phoneNumber ? <small className='text-danger'>{error.phoneNumber}</small> : <small>{t("Phone number should be 10 digits")}</small>
                                    }
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input
                                            value={checked} onChange={(event) => {
                                                setChecked(event.target.checked)
                                            }} className="form-check-input" type="checkbox" id="gridCheck" />
                                        <small className="form-check-label" htmlFor="gridCheck">
                                            {t("By checking you confirm that you have read and agree with the")}
                                            <a href={WEB_BASE_URL_LINK + "/terms-of-use"} rel="noreferrer" className="mr-3" style={{ marginLeft: '5px', color: '#000000', fontWeight: 800 }}>{t("Terms and Conditions")}</a>
                                            <span style={{ marginLeft: '3px', color: "#ff0000", fontWeight: 700 }}>*</span>
                                        </small>
                                    </div>
                                </div>
                                {
                                    checked && firstName && lastName && email && zipCode && phoneNumber ? <div className="col-12">
                                        <button onClick={() => { handleSubmitData() }} className="btn btn-danger btn-circle csl-button-join-us" type="submit">{t('Submit')}</button>
                                    </div> : <div className="col-12">
                                        <button className="btn btn-danger btn-circle csl-button-join-us disabled" type="submit">{t('Submit')}</button>
                                    </div>
                                }
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </Translation>
    )
}
export default FormModalDesktop