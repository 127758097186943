import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './Layouts/Layout';
import { Outlet } from 'react-router-dom'
import Helmet from 'react-helmet';
import i18n from "i18next";

function App() {
  const lng = i18n.language;
  console.log(`lng: `, lng)
  const [mata1, setMeta1] = useState(lng === 'en' ? `Come together with patients and lifesavers via CSL Plasma’s Plasma P.A.L.S. Our program is dedicated to raising awareness around plasma donation and more.` 
  : `Come together with patients and lifesavers via CSL Plasma’s Plasma P.A.L.S. Our program is dedicated to raising awareness around plasma donation and more.`)
  const [mata2, setMeta2] = useState(lng === 'en' ? `Plasma P.A.L.S. is a program that helps raise awareness of the importance of plasma donation and the role plasma donors play in creating life-saving therapies.` 
  : `Plasma P.A.L.S es un programa que ayuda a crear conciencia sobre la importancia de la donación de plasma y el papel que juegan los donantes de plasma en la creación de terapias que salvan vidas.`)

  return (
    <>
    <Helmet>
        {/* <title>Plasma P.A.L.S. | CSL Plasma</title> */}
        <meta
          name="Plasma P.A.L.S."
          content={mata1}
          />
        <meta
          name="description"
          content={mata2}
          />
    </Helmet>
    <div>
      <Layout>
        <Outlet />
      </Layout>
    </div>
    </>
  );
}

export default App;
