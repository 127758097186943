import React, { Fragment } from 'react'
import BannerDeskTop from './Desktop/BannerDeskTop'
import BannerMobile from './Mobile/BannerMobile'

const Banner = () => {
    return (
        <Fragment>
            <BannerDeskTop />
            <BannerMobile/>
            <div className='line_gold'/>
        </Fragment>
    )
}

export default Banner