import React, { useState } from 'react'
import "../../Assets/Styles/Mobile_Styles/ContentsSlideMobile.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from "swiper";
import { Translation } from 'react-i18next';
import VideoModalMobile from '../Modals/VideoModalMobile';
import PlayVideoIcon from '../PlayVideoIcon';

const DonorData = [
    {
        name: "Theresa",
        country: "- Wisconsin",
        description: "“I started donating because I wanted to help out my mom, and I continue because I realized it really does help people.”",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Donors/image-Mandi.png"),
        pathname: "CECtmq0FSbg",
        url: "Watch Theresa’s story"
    },
    {
        name: "Kathy",
        country: "- South Carolina",
        description: "“I started donating plasma because I felt that there is a need for it. It makes me feel great that I can do this for somebody that needs it so much. ”",
        image: require("../../Assets/Desktop/Meet-Our-Lifesavers-Donors/image-Jose.png"),
        pathname: "ESatTnEu1sw",
        url: "Watch Kathy’s story"
    }
]

const DonorSlideMobile = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [urlVideos, setUrlVideos] = useState();

    const HandleModel = (url) => {
        setUrlVideos(url);
        setModalOpen(!modalOpen);
    }
    return (
        <Translation>
            {t => (
                <div className='d-md-none d-block donor_body'>
                    <div className="mx-5 d-flex flex-col align-items-center" style={{ paddingTop: '55px' }} >
                        <div className='d-flex flex-col align-items-center'>
                            <div className="title_content d-flex flex-col align-items-center text-center">
                                <h2 className="first_title">{t('Meet our Lifesavers')}<strong className="second_title"><br />{t('DONORS')}</strong></h2>
                            </div>
                            <div className='row g-0 justify-content-center'>
                                <p className="sub_caption_donor col-10">
                                    {t('Plasma is an important element of the human body that can make a huge difference for those who need it. Here’s why our donors donate.')}
                                </p>
                            </div>
                        </div>

                        <div className='box_content_donor' style={{ marginBottom: '80px' }}>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                centeredSlides={true}
                                modules={[Pagination]}
                                pagination={{
                                    clickable: true,
                                }}
                                loop={true}
                                className="mySwiper"
                                id="swiper-replace"
                            >
                                {
                                    DonorData.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <div className='content_donor'>
                                                <div className='outline_image'>
                                                <PlayVideoIcon marginTopH="50px" position="absolute" marginLeft="60px" HandleModel={() => HandleModel(item.pathname)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-14px" backgroundColor="black" />
                                                    <img className='image_donor' alt='' src={item.image} />
                                                </div>
                                                <div className='donor_description row g-0 justify-content-center'>
                                                    <div className='donor_users'>
                                                        <h3 className='donor_name'>{item.name}</h3>
                                                    </div>
                                                    <p className='donor_caption col-10'>
                                                        {t(item.description)}
                                                    </p>
                                                </div>
                                                <a onClick={() => HandleModel(item.pathname)} className="bootstrap-link" style={{ textDecoration: 'none' }}>
                                                    <p className='donor_link_text'>
                                                        {t(item.url)}
                                                    </p>
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className="line_green">

                    </div>
                    <VideoModalMobile
                        setShow={setModalOpen}
                        show={modalOpen}
                        HandleModel={HandleModel}
                        setModalOpen={setModalOpen}
                        urlVideos={urlVideos}
                    />
                </div>
            )}
        </Translation>
    )
}

export default DonorSlideMobile