
const languages = ['es'];
let url = window.location.pathname;
var langs =  url.substring(url.lastIndexOf('/') + 1)
const lang = languages.includes(langs) ? `/${langs}` : '';


export const API_POTENTIAL = "external/potential-donors/submit"



// Prod Deployment
// export const WEB_BASE_URL_LINK = "https://www.cslplasma.com" + lang;
// export const WEB_CSLPLASMA_LINK = "https://plasmapals.cslplasma.com" + lang;
// export const API_ENDPOINT = "https://donorapp-api.cslplasma.com/"
// export const MY_ACCOUNT_URL = "https://rewards.cslplasma.com/?pk_vid=c5156318035dc36f166659207886d04b"

// QA Deployment
export const WEB_BASE_URL_LINK = "https://qa08.cslplasma.com" + lang;
export const WEB_CSLPLASMA_LINK = "https://plasmapals.cslplasma.com" + lang;
export const API_ENDPOINT = "https://donorapp-apiqa.cslplasma.com/"
export const MY_ACCOUNT_URL = "https://rewards-qa.cslplasma.com/?pk_vid=c5156318035dc36f166659207886d04b"
