//CHECK FOR VALID EMAIL FORMAT
export function validateEmail(email) {
  var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email.trim()).toLowerCase());
}

//CHECK FOR VALID PHONE FORMAT
export function validatePhone(phone){
  return /^\d+$/.test(phone) && phone.length > 8 && phone.length < 12
}

//CHECK FOR VALID NAME FORMAT
export const validateName = str => {
  var name = str.replace(/^([^0-9<>()\[\]\\.,;:\s@#$!]*)$/);
  if (str === '') {
    return true;
  }
  return /^[a-z ,.*'‘’_-]+$/i.test(name);
};