import React from 'react'
import { Modal } from 'react-bootstrap'
import '../../Assets/Styles/Desktop_Styles/PotentialDonor.css'

const VideoModal = (props) => {
    const { show, setShow, urlVideos } = props
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose} centered
        className="modal fade bd-example-modal-lg desktop1"
        >
            <Modal.Body>
                <div className="col-md-12">
                    <iframe width="850" autoplay={true} height="543" src={`https://www.youtube.com/embed/${urlVideos}?autoplay=1`} allow="autoplay" title="Patient Story: Alice&#39;s Journey with Common Variable Immunodeficiency (CVID)  | CSL Plasma" frameborder="0" allowfullscreen></iframe>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default VideoModal