import React from "react";

const ButtonMoreStories = (props) => {
    return (
        <div className='text-center buttonColor' style={{ lineHeight: '200px', }}>
            <a href={props.url} style={{
                width: '360px',
                height: '60px',
                fontSize:'20px',
                borderRadius:'100px',
                fontFamily: 'Montserrat-bold',
                opacity: 1,
                textAlign:'center',
                display:'inline-block',
                lineHeight:'60px',
                margin:'0 auto',
                padding:'0px',
                color:'#fc1921',
                borderColor:'#fc1921',
                marginTop:'-40px'

            }} type="button" className={`${props.name}`}>{props.title}</a>
        </div>
    )
}

export default ButtonMoreStories