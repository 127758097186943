import React, { useRef } from 'react'
import { Translation } from 'react-i18next'
import ReactPlayer from 'react-player'

const TogetherDesktop = () => {
    const playerRef = useRef(null);
    return (
        <Translation>
            {t => (
                <div
                    className='col-md-12 Together d-md-block d-none'
                    style={{
                        paddingTop: '40px',
                        backgroundColor: "#f2f2f1",
                        clipPath: "polygon(0px 0px, 100% 0px, 100% 95%, 0% 100%)",
                        width: '100%',
                        height: '800px',
                    }}
                >
                    <h2>{t("Together, we can")}<span className='amazing'><br />{t('DO THE AMAZING')}</span></h2>
                    <div className='row'>
                        <p style={{ marginTop: '25px', marginBottom: "5rem" }}>
                            {t('Do you rely on plasma derived therapies? Your participation in Plasma P.A.L.S. can help show the real-life impact that both donors and employees have in saving lives through plasma donation.')}
                        </p>
                    </div>
                    <div className='' style={{ margin: "auto", width: "718px", border: "3px solid #FFA814", borderRadius: "5px", display: 'flex', justifyContent: "center" }}>
                        <ReactPlayer width="715px" ref={playerRef} url="https://youtu.be/ROgdN4hAqQU" controls={true} />
                    </div>
                </div>
            )}
        </Translation>
    )
}

export default TogetherDesktop