import React from "react";
import { Translation } from "react-i18next";
import "../../Assets/Styles/Mobile_Styles/Didyouknow.css";

const DidyouknowMobile = () => {
    return (
        <Translation>
            {t => (
                <div className="d-md-none d-block">
                    <img
                        alt=""
                        className="did_image"
                        src={require("../../Assets/Desktop/Did_you_know/original-mage-Ashlee-2.jpg")}
                    />
                    <div className="did_content">
                        <div className="px-5 row g-0 text_content_did">
                            <h2 className="did_title col">{t('Did you know?')}</h2>
                            <p className="did_description col-11">
                                {t('It takes more than 200 plasma donors to help support one plasma-based treatment that many patients need monthly. This program helps spread awareness around the need for plasma donation.')}
                            </p>
                        </div>
                        <img
                            alt=""
                            className="did_heart float-start"
                            src={require("../../Assets/Mobile/Did-you-know/heart_mobile.png")}
                        />
                    </div>
                    <div className="line_gold">

                    </div>
                </div>
            )}
        </Translation>
    );
};

export default DidyouknowMobile;
