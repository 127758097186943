import React from "react";
import { Translation } from "react-i18next";

const DidyouknowDesktop = () => {
    return (
        <Translation>
            {t => (
                <div className='col-md-12 d-md-block d-none' style={{ overflow: 'hidden' }}>
                    <section className='' style={{ width: '100%', height: '15px', backgroundColor: '#FFA814' }}></section>
                    <div className="row didknow d-flex flex-row gx-0" style={{ backgroundColor: '#03b3be' }}>
                        <div className='d-flex flex-lg-row flex-col col-lg-8 order-lg-1 order-2'>
                            <div className='col-lg-5 heart order-lg-1 order-2' style={{ marginTop: '25px' }}>
                                <img style={{ width: '530px' }} src={require("../../Assets/Desktop/Did_you_know/heart1.png")} className="img-fluid" alt="..."></img>
                            </div>
                            <div className='col-lg-7 order-lg-2 order-1' style={{ marginTop: '80px' }}>
                                <h2>{t('Did you know?')}</h2>
                                <p>
                                    {t('It takes more than 200 plasma donors to help support one plasma-based treatment that many patients need monthly. This program helps spread awareness around the need for plasma donation.')}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 people order-lg-2 order-1'>
                            <img src={require("../../Assets/Desktop/Did_you_know/image.png")} className="img-fluid" alt="..."></img>
                        </div>
                    </div>
                    <section className='' style={{ width: '100%', height: '15px', backgroundColor: '#FFA814' }}></section>
                </div>
            )}
        </Translation>
    )
}

export default DidyouknowDesktop