import React from 'react'
import '../../Assets/Styles/Mobile_Styles/BannerMobile.css'
import BannerImageMobile from '../../Assets/Mobile/Banner/Banner_Mobile.jpg'
import BannerLogo from '../../Assets/Desktop/1. Banner/logo.png'
import { Translation } from 'react-i18next'

const BannerMobile = () => {
    return (
        <Translation>
            {t => (
                <div className='banner_mobile d-md-none d-block' style={{ overflow: 'hidden' }}>
                    <div className='banner_image_mobile' style={{
                        backgroundImage: `url(${BannerImageMobile})`
                    }}>
                        <div className='row h-25'>
                            <div className='col' />
                            <div className='col mt-5 me-4 d-flex flex-row align-items-start justify-content-end'>
                                <img alt='' src={BannerLogo} className="banner_logo_mobile float-end" />
                            </div>
                        </div>
                        <div className='glass_content_mobile glassmorphism'>
                            <div className='container'>
                                <div className='banner_title_mobile'>
                                    <h1 className='primary_text_mobile'>{t("Do the Amazing with Plasma P.A.L.S.")}</h1>
                                </div>
                                <div className='banner_description_mobile row'>
                                    <p className='description_text_mobile col-10'>
                                        {t("Plasma P.A.L.S. is a program that helps raise awareness of the importance of plasma donation and the role plasma donors play in creating life-saving therapies. A place for patients and lifesavers to come together.")}
                                    </p>
                                    <div className='col' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Translation>
    )
}

export default BannerMobile