import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import './SwitchLanguage.css'

const SwitchLanguage = () => {

    const { i18n } = useTranslation();
    const {langs} = useParams();
    const languages = ['en', 'es'];

    function changeLanguage(e) {
        i18n.changeLanguage(e);
    }
    const lang = i18n.language;
    useEffect(()=> {
        let codeLang = languages.includes(langs) ? langs : 'en';
        i18n.changeLanguage(codeLang)
    }, [langs]);

    return (
        <ul className='switch_language_body'>
            <li>
                <a href='/' onClick={() => changeLanguage("en")}>
                    <p className='switch_language' style={{ fontWeight: `${lang === 'en' ? '800' : ''}`, fontFamily: `${lang === 'en' ? 'Montserrat-Bold' : ''}` }}>EN</p>
                </a>
            </li>
            <p className='mx-2'>|</p>
            <li className='es'>
                <a href='/es' onClick={() => changeLanguage("es")}>
                    <p className='switch_language' style={{ fontWeight: `${lang === 'es' ? '800' : ''}`, fontFamily: `${lang === 'es' ? 'Montserrat-Bold' : ''}` }}>ES</p>
                </a>
            </li>
        </ul>
    )
}

export default SwitchLanguage