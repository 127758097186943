import React, { useState } from 'react'
import EmailIcons from '../Assets/Desktop/Icons/icon-email.png'
import HelpIcons from '../Assets/Desktop/Icons/help-white.png'
import './secondaryFooter.css'
import '../Assets/Styles/Mobile_Styles/PotentialDonorMoblie.css'
import FormModalMobile from '../Components/Modals/FormModalMobile'
import { Translation } from 'react-i18next'
import { validateEmail } from '../utils/validation'

const SecondaryFooter = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState()
    const [error, setError] = useState({})
    const [emailValid, setEmailValid] = useState([])

    const HandleModel = () => {
        setModalOpen(!modalOpen);
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        setError({
            ...error,
            email: event.target.value && !validateEmail(event.target.value) ? "Must be valid email address" : ""
        })
        return false
    }

    return (
        <Translation>
            {t => (
                <div className='d-md-none d-block'>
                    <section className='footer-secondary'>
                        <div className='row section-secondary container'>
                            <div className='col-lg-6'>
                                <p className='secondary_title_mobile'>{t('Help us create awareness. Join Plasma P.A.L.S.!')}</p>
                                <p className='secondary_description_mobile'>{t('To find out more visit your CSL Plasma Center or contact us at:')}</p>
                                <div className='position_icon_mobile'>
                                    <img alt='' src={EmailIcons} style={{ width: '25px' }} />
                                    <span className='icons_text_mobile'>
                                        <a href='mailto:plasma.marketing@cslplasma.com' style={{ textDecoration: 'none', color: 'white' }}>plasma.marketing@cslplasma.com</a>
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-2'>

                            </div>
                            <div className='col-lg-4 submit-form mt-5 mt-lg-0 d-flex flex-col justify-content-between'>
                                <div className='w-100 mb-4'>
                                    <div className='email_position_mobile mb-3'>
                                        <p className='email_title_mobile'>{t('Email address')}</p>
                                        <div className='tooltip_footer'>
                                            <img alt='' src={HelpIcons} style={{ width: '25px', height: '25px' }} />
                                            <span className='tooltiptext'>
                                                {t("Sign up to receive information about plasma donation and see new donor fees in your area.")}
                                            </span>
                                        </div>
                                    </div>
                                    <input value={email} onChange={handleChangeEmail} className='border-0 w-100' style={{ height: '40px', borderRadius: '3px', outline: 'none', padding: '1rem' }} />
                                </div>
                                {
                                    email && !error.email ? <button className='form-button' style={{ marginTop: '25px' }} onClick={() => HandleModel()}>
                                        {t('Submit')}
                                    </button> : <button className='form-button-disabled' style={{ marginTop: '25px' }} >
                                        {t('Submit')}
                                    </button>
                                }
                            </div>
                        </div>
                    </section>
                    <FormModalMobile modalOpen={modalOpen} HandleModel={HandleModel} emailValue={email}
                        emailValid={emailValid}
                        setEmailValid={setEmailValid}
                        handleEmail={(e) => {
                            setEmail(e)
                        }} setModalOpen={setModalOpen} />
                </div>
            )}
        </Translation>
    )
}

export default SecondaryFooter