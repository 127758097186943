import React, { useState } from 'react'
import image1 from '../../Assets/Desktop/Meet_Our_Patients/image-two-strokes-krissy.png'
import image4 from '../../Assets/Desktop/Meet_Our_Patients/image-two-strokes-Benjamin.png'
import image2 from '../../Assets/Desktop/Meet_Our_Patients/image-with-background.jpg'
import image3 from '../../Assets/Desktop/Meet_Our_Patients/image-two-strokes-and-fill-nicholas.png'
import { WEB_BASE_URL_LINK } from '../../config/api'
import { Translation } from 'react-i18next'
import TogetherDesktop from './TogetherDesktop'
import VideoModal from '../Modals/VideoModal'
import PlayVideoIcon from '../PlayVideoIcon'
import i18n from "i18next";


const AmazingComponent = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [urlVideos, setUrlVideos] = useState();
    const lng = i18n.language;

    const HandleModel = (url) => {
        setUrlVideos(url);
        setModalOpen(!modalOpen);
    }
    return (
        <Translation>
            {t => (
                <div className='col-md-12 d-md-block d-none' style={{ overflow: 'hidden' }}>
                    <section className="">
                        <section className='bgColor'>
                            <TogetherDesktop />
                            <div className="row container patients" style={{ margin: 'auto' }}>
                                <div className="col-12 col-sm-12 col-md-12	col-lg-12 col-xl-12 col-xxl-12 Together mx-auto mb-4">
                                    <h2>{t('Meet our')}<span className='amazingDes'><br />{t('PATIENTS')}</span></h2>
                                    <div className='row'>
                                        <p className='description_text_banner col'>
                                            {t('Plasma is the key ingredient for medicines crucial to treating patients suffering from several life-threatening conditions around the world. Meet some of our patients whose lives have been changed by the life-saving role that all our donors play.')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row bgImage- gx-0"
                                style={{
                                    backgroundImage: `url(${image2})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'right',
                                    backgroundPosition: "bottom",
                                    backgroundSize: 'contain',
                                    marginTop: "-80px"
                                }}
                            >
                                <div className="col-12 col-sm-12 col-md-12	col-lg-12 col-xl-12 col-xxl-12" style={{ marginTop: '80px' }}>
                                    <div className='row pl-2rem text-center'>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-4 colflex">
                                            <img alt='' src={image4} className="imgw-332 img-fluid" style={{ width: '375px', height: '410px' }} />
                                            <PlayVideoIcon position="absolute" marginLeft="-400px" HandleModel={() => HandleModel(`${lng === 'en' ? 'fEo8987Xd7Y' : 'U7TYMtyoOJM'}`)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-16px" backgroundColor="black" />
                                            <div style={{ textAlign: 'left' }} className='divBorder glassmorphism_blue divBorderL divBorderRaR pading-2rem'>
                                                <h3 style={{ fontSize: '28px', fontFamily: 'Montserrat-Bold', color: '#4F5556', textAlign: "center" }}>{t('Benjamin')}</h3>
                                                <p style={{ textAlign: 'left', fontFamily: 'Montserrat', fontSize: '20px', color: '#4F5556' }}>
                                                    {t(`“Every day I wake up, I'm going to fight as needed so I can keep making a difference in everything I'm doing. And if I can make a difference and make the world a better place, then I'll do that in any capacity I can every morning I wake up.”`)}
                                                </p>
                                                <a onClick={() => HandleModel(`${lng === 'en' ? 'fEo8987Xd7Y' : 'U7TYMtyoOJM'}`)} className="bootstrap-link" style={{ cursor: "pointer" }}>{t('Watch Benjamin’s story')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pr-2rem'>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-4 colflex">
                                            <div className='divBorder glassmorphism_blue1 divBorderR divBorderRaRB text-end mt23 pading-2rem' >
                                                <h3 style={{ fontSize: '28px', fontFamily: 'Montserrat-Bold', color: '#4F5556', textAlign: "center" }}>{t('Megan')}</h3>
                                                <p style={{ textAlign: 'right', fontFamily: 'Montserrat', fontSize: '20px', color: '#4F5556' }}>
                                                    {t('“Normal is a really relative term and I think sometimes we put that word normal, and we should put that aside because what life is like from a normal for one person is different from the next.”')}
                                                </p>
                                                <a onClick={() => HandleModel(`${lng === 'en' ? "p6tpnYGhrE8" : 'lOYnsWo1h8s'}`)} className="bootstrap-link" style={{ cursor: "pointer" }}>{t('Watch Megan’s story')}</a>
                                            </div>
                                            <PlayVideoIcon position="absolute" marginLeft="450px" HandleModel={() => HandleModel(`${lng === 'en' ? "p6tpnYGhrE8" : 'lOYnsWo1h8s'}`)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-16px" backgroundColor="black" />
                                            <img alt='' src={image3} className="imgw-332 img-fluid" style={{ width: '375px', height: '410px' }} />
                                        </div>
                                    </div>
                                    <div className='row pl-2rem text-center'>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5 colflex">
                                            <PlayVideoIcon position="absolute" marginLeft="-400px" HandleModel={() => HandleModel(`${lng === 'en' ? "p7awdHRjW18" : 'nFiD-botMJM'}`)} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-16px" backgroundColor="black" />
                                            <img alt='' src={image1} className="imgw-332 img-fluid" style={{ width: '375px', height: '410px' }} />
                                            <div style={{ textAlign: 'left' }} className='divBorder glassmorphism_blue divBorderL divBorderRaR pading-2rem'>
                                                <h3 style={{ fontSize: '28px', fontFamily: 'Montserrat-Bold', color: '#4F5556', textAlign: "center" }}>{t('Alice')}</h3>
                                                <p style={{ textAlign: 'left', fontFamily: 'Montserrat', fontSize: '20px', color: '#4F5556' }}>
                                                    {t(`“Because of plasma donations, I'm alive. The disease came as a surprise to me was when I was 54 years old. The name of the disease is called Common Variable Immune Deficiency”`)}
                                                </p>
                                                <a onClick={() => HandleModel(`${lng === 'en' ? "p7awdHRjW18" : 'nFiD-botMJM'}`)} className="bootstrap-link" style={{ cursor: "pointer" }}>{t('Watch Alice’s story')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center btnPatient' style={{ lineHeight: '200px', }}>
                                        <a href={WEB_BASE_URL_LINK + "/stories"} style={{
                                            width: '360px',
                                            height: '60px',
                                            fontSize: '20px',
                                            borderRadius: '100px',
                                            fontFamily: 'Montserrat-bold',
                                            opacity: 1,
                                            textAlign: 'center',
                                            lineHeight: '60px',
                                            margin: '0 auto',
                                            padding: '0px',
                                            backgroundColor: '#FC1921'

                                        }} type="button" className="btn btn-danger btn-circle">{t('MORE PATIENT STORIES')}</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                    <VideoModal
                        setShow={setModalOpen}
                        show={modalOpen}
                        HandleModel={HandleModel}
                        setModalOpen={setModalOpen}
                        urlVideos={urlVideos}
                    />
                </div>
            )}
        </Translation>
    )
}

export default AmazingComponent