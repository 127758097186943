import React from 'react'
import './FooterNavigationMobile.css'
import { FaLinkedinIn, FaTwitter, FaYoutube, FaFacebookF } from 'react-icons/fa'
import AppStore from '../Assets/Images/AppStore.png'
import GooglePlay from '../Assets/Images/GooglePlay.png'
import CSLPlasma from '../Assets/Images/CSL_Logo.png'
import { WEB_BASE_URL_LINK, WEB_CSLPLASMA_LINK } from '../config/api'
import SwitchLanguage from '../Components/SwitchLanguage/SwitchLanguage'
import { Translation } from 'react-i18next'

const FooterNavigationMobile = () => {

    const communityData = [
        {
            title: "Plasma P.A.L.S",
            link: WEB_CSLPLASMA_LINK + ""
        },
        {
            title: "Community",
            link: WEB_BASE_URL_LINK + "/community-outreach"
        }
    ]

    const companyData = [
        {
            title: "About Us",
            link: WEB_BASE_URL_LINK + "/about-us"
        },
        {
            title: "Innovation",
            link: WEB_BASE_URL_LINK + "/donation-experience-reimagined"
        },
        {
            title: "Newsroom",
            link: WEB_BASE_URL_LINK + "/newsroom"
        },
    ]

    const helpData = [
        {
            title: "Contact Us",
            link: WEB_BASE_URL_LINK + "/contact-us"
        },
        {
            title: "FAQs",
            link: WEB_BASE_URL_LINK + "/faq"
        }
    ]
    return (
        <Translation>
            {t => (
                <div className='d-md-none d-block'>
                    <section className='footer-primary_mobile'>
                        <div className='row container p-0 g-4'>
                            <div className='col-lg-9 p-0'>
                                <div className='row'>
                                    <ul className='col-lg-3 col-6' style={{ listStyle: 'none' }}>
                                        <li className='mb-3'>
                                            <p className='footer_title_mobile'>{t('Community')}</p>
                                        </li>
                                        {
                                            communityData.map((item, index) => (
                                                <li key={index} className='mb-2'>
                                                    <a href={item.link} className='text-decoration-none link-dark' rel="noreferrer">
                                                        <p className='footer_navigation_mobile'>{t(item.title)}</p>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <ul className='col-lg-2 col-6' style={{ listStyle: 'none' }}>
                                        <li className='mb-3'>
                                            <p className='footer_title_mobile'>{t('Company')}</p>
                                        </li>
                                        {
                                            companyData.map((item, index) => (
                                                <li key={index} className='mb-2'>
                                                    <a href={item.link} className='text-decoration-none link-dark' rel="noreferrer">
                                                        <p className='footer_navigation_mobile'>{t(item.title)}</p>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <ul className='col-lg-3 col-6 mt-lg-0 mt-4' style={{ listStyle: 'none' }}>
                                        <li className='mb-3'>
                                            <p className='footer_title_mobile'>{t('Help')}</p>
                                        </li>
                                        {
                                            helpData.map((item, index) => (
                                                <li key={index} className='mb-2'>
                                                    <a href={item.link} className='text-decoration-none link-dark' rel="noreferrer">
                                                        <p className='footer_navigation_mobile'>{t(item.title)}</p>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className='col-lg-4 col-6 mt-lg-0 mt-4'>
                                        <div className='d-flex flex-row'>
                                            <a href='https://www.linkedin.com/company/csl-plasma/' className='text-decoration-none link-dark' rel="noreferrer">
                                                <FaLinkedinIn size={18} />
                                            </a>
                                            <a href='https://twitter.com/CSLPlasma' className='text-decoration-none link-dark' rel="noreferrer">
                                                <FaTwitter size={18} style={{ marginRight: '26px', marginLeft: '26px' }} />
                                            </a>
                                            <a href='https://www.youtube.com/user/CSLPlasma' className='text-decoration-none link-dark' rel="noreferrer">
                                                <FaYoutube size={18} />
                                            </a>
                                            <a href='https://www.facebook.com/CSLPlasmaInc/' className='text-decoration-none link-dark' rel="noreferrer">
                                                <FaFacebookF size={18} style={{ marginLeft: '26px' }} />
                                            </a>
                                        </div>
                                        <div className='mt-3' style={{ fontSize: "18px" }}>{t("Download the App")}</div>
                                        <div className='app_download_mobile'>
                                            <a href='https://apps.apple.com/us/app/csl-plasma/id1503641894' className='text-decoration-none link-dark' rel="noreferrer">
                                                <img className='w-100 h-100' style={{ objectFit: 'cover' }} alt='appstore' src={AppStore} />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=com.cslplasma.cslplasmadonorapplication&hl=en_US&gl=US' className='text-decoration-none link-dark' rel="noreferrer">
                                                <img className='w-100 h-100' style={{ objectFit: 'cover', marginTop: '10px' }} alt='googleplay' src={GooglePlay} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 p-0'>
                                <a href={WEB_BASE_URL_LINK} rel="noreferrer">
                                    <img width="150px" height="auto" src={CSLPlasma} alt="CSLPlasma" />
                                    {/* <img alt='' src={CSLPlasma} width='135px' className="mx-lg-4 mx-0 my-lg-0 my-4" /> */}
                                </a>
                            </div>
                            <div className='col-6 p-3 d-flex flex-row justify-content-start'>
                                <SwitchLanguage />
                            </div>
                            <div className='row p-0'>
                                <div className='col-12 col-lg-6 order-lg-1 order-2 mt-lg-0 mt-4'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className='mb-2 d-flex align-items-center'>
                                            <a href={WEB_BASE_URL_LINK + '/cookie-policy'} className='text-decoration-none link-dark' rel="noreferrer">
                                                <p className='footer_navigation_mobile'>{t('Cookie Policy')}</p>
                                            </a>
                                            <span style={{ padding: '0px 10px' }}>|</span>
                                        </div>
                                        <div className='mb-2 d-flex align-items-center'>
                                            <a href={WEB_BASE_URL_LINK + '/privacy-policy'} className='text-decoration-none link-dark' rel="noreferrer">
                                                <p className='footer_navigation_mobile'>{t('Privacy Policy')}</p>
                                            </a>
                                            <span style={{ padding: '0px 10px' }}>|</span>
                                        </div>
                                        <div className='mb-2 d-flex align-items-center'>
                                            <a href={WEB_BASE_URL_LINK + '/terms-of-use'} className='text-decoration-none link-dark' rel="noreferrer">
                                                <p className='footer_navigation_mobile'>{t('Terms of Use')}</p>
                                            </a>
                                            <span style={{ padding: '0px 10px' }}>|</span>
                                        </div>
                                        <div className='mb-2 d-flex align-items-center'>
                                            <a href={WEB_BASE_URL_LINK + '/accessibility'} className='text-decoration-none link-dark' rel="noreferrer">
                                                <p className='footer_navigation_mobile'>{t('Accessibility')}</p>
                                            </a>
                                            <span style={{ padding: '0px 10px' }}>|</span>
                                        </div>
                                        <div className='mb-2'>
                                            <a href={WEB_BASE_URL_LINK + '/sitemap'} className='text-decoration-none link-dark' rel="noreferrer">
                                                <p className='footer_navigation_mobile'>{t('Sitemap')}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Translation>
    )
}

export default FooterNavigationMobile