import React, { Fragment } from 'react'
import TogetherDesktop from './Desktop/TogetherDesktop'
import './Mobile/TheAmazingMobile'
import TheAmazingMobile from './Mobile/TheAmazingMobile'
import TheAmazingDesktop from './Desktop/TheAmazingDesktop'

const TheAmazing = () => {
    return (
        <Fragment>
            <TheAmazingMobile />
            <TheAmazingDesktop />
        </Fragment>
    )
}

export default TheAmazing