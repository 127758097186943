import React, { useState } from 'react'
import CSLPlasma from '../Assets/Images/CSL_Logo.png'
import './MobileHeader.css'
import { MY_ACCOUNT_URL, WEB_BASE_URL_LINK } from '../config/api'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdLocationOn, MdOutlineAccountCircle } from 'react-icons/md'
import { Translation } from 'react-i18next'

const MobileHeader = () => {

    const [active, setActive] = useState(false)
    const [isshowChild, setIsshowChild] = useState(null)

    const onClickActive = () => {
        setActive(!active)
        setIsshowChild(null)
    }

    if (active === true) {
        document.body.style.overflow = 'visable	';
    } else {
        document.body.style.overflow = 'unset';
    }

    const menus = [
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/start-donating",
            title: "Start Donating",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/online-screening",
                    title: "Online Screening",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/why-plasma",
            title: "Why Plasma?"
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/be-rewarded",
            title: "Be Rewarded",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/igive-rewards",
                    title: "iGive Community",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/careers",
            title: "Careers",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/employee-benefits",
                    title: "Employee Benefits",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/faq",
            title: "FAQs"
        },
    ]

    return (
        <Translation>
            {t => (
                <nav className='navbar navbar-expand-lg navbar-light text-dark bg-light d-lg-none d-flex'>
                    <a href={WEB_BASE_URL_LINK} rel="noopener noreferrer" className='mx-5'>
                        <img alt='' src={CSLPlasma} style={{ width: '100px' }} />
                    </a>
                    <button className="navbar-toggler"
                        onClick={onClickActive}
                        type="button" data-toggle="collapse"
                        style={{
                            position: 'absolute',
                            right: '3rem',
                        }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={
                        active ?
                            'dropdown_menu' : 'dropdown_menu_down'
                    }>
                        <div className='body_navigation_menu'>
                            {
                                menus.map((m, index) => (
                                    <>
                                        <div key={index} className='d-flex flex-row align-items-center justify-content-between' style={{ cursor: 'pointer' }}>
                                            <a
                                                href={m.link}
                                                className='h-100' style={{ textDecoration: 'none', padding: '8px', color: 'black' }}>
                                                <p className={'navigation_text_menu'}>
                                                {t(m.title)}
                                                </p>
                                            </a>
                                            {
                                                m.children && m.children.length > 0 &&
                                                <>
                                                    {isshowChild === index + 1 ? <MdKeyboardArrowUp size={40} onClick={() => {
                                                        if (isshowChild !== index + 1) {
                                                            setIsshowChild(index + 1)
                                                        } else {
                                                            setIsshowChild(null)
                                                        }
                                                    }} />
                                                        : <MdKeyboardArrowDown size={40} onClick={() => {
                                                            if (isshowChild !== index + 1) {
                                                                setIsshowChild(index + 1)
                                                            } else {
                                                                setIsshowChild(null)
                                                            }
                                                        }} />}
                                                </>
                                            }
                                        </div>
                                        {isshowChild === index + 1 &&
                                            m.children &&
                                            <div style={{
                                                border: '1px solid rgba(0,0,0,0.15)',
                                                borderRadius: '0.25rem',
                                                padding: '1rem 0.5rem',
                                                marginBottom: '0',
                                            }}>
                                                {m.children.map((ch, Inch, index) => (
                                                    <a
                                                        href={ch.link}
                                                        onClick={() => {
                                                            setIsshowChild(null)
                                                            onClickActive()
                                                        }}
                                                        style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'black', cursor: 'pointer' }}
                                                        key={ch.link}
                                                    >
                                                        {t(ch.title)}
                                                    </a>
                                                ))}
                                            </div>
                                        }
                                    </>
                                ))}
                            <div className='d-flex flex-column align-items-center' style={{ width: '100%' }}>
                                <div className='d-flex flex-row align-items-center' style={{ height: '72px' }}>
                                    <MdLocationOn size={32} style={{ color: 'FC1921' }} />
                                    <a href={WEB_BASE_URL_LINK + "/find-a-donation-center"} style={{ fontSize: '18px', color: 'black', textDecoration: 'none' }}>{t("Find Nearest Center")}</a>
                                </div>
                                <div className='d-flex flex-row align-items-center' style={{ height: '59px' }}>
                                    <MdOutlineAccountCircle size={24} style={{ marginRight: '8px' }} />
                                    <a href={MY_ACCOUNT_URL} rel="noreferrer" style={{ fontSize: '18px', color: 'black', textDecoration: 'none' }}>{t("My Account/Sign Up")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            )}
            </Translation>

    )
}

export default MobileHeader