import React from 'react'
import Banner from '../Components/Banner'
import TheAmazing from '../Components/TheAmazing'
import Didyouknow from '../Components/Didyouknow'
import Donor from '../Components/Donor'
import Employee from '../Components/Employee'

const LandingPage = () => {
  return (
    <div className='col-md-12'>
      <Banner />
      <TheAmazing />
      <Didyouknow />
      <Donor />
      <Employee />
    </div>
  )
}

export default LandingPage