import React, { Fragment } from 'react'
import DidyouknowDeskTop from './Desktop/DidyouknowDesktop'
import DidyouknowMobile from './Mobile/DidyouknowMobile'

const Didyouknow = () => {
    return (
        <Fragment>
            <DidyouknowMobile />
            <DidyouknowDeskTop />
        </Fragment>
    )
}

export default Didyouknow