import React from 'react';
import { FaPlay } from 'react-icons/fa';

const PlayVideoIcon = (props) => {
    const {position, marginTopH, marginLeft, HandleModel, size, width, height, borderRadius, opacity, marginLeftBg, marginTop, backgroundColor} = props;
    return (
        <div className='d-flex' style={{ marginTop:marginTopH, position: position, marginLeft: marginLeft, cursor: "pointer" }} onClick={HandleModel}>
            <FaPlay size={size} color='white' />
            <div style={{ width: width, height: height, borderRadius: borderRadius, opacity: opacity, marginLeft: marginLeftBg, marginTop: marginTop, backgroundColor: backgroundColor }}></div>
        </div>
    )
}
export default PlayVideoIcon;