import React from 'react'
import { Modal } from 'react-bootstrap'
import '../../Assets/Styles/Desktop_Styles/PotentialDonor.css'

const VideoModalMobile = (props) => {
    const { show, setShow, HandleModel, urlVideos } = props
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}
        className="modal fade bd-example-modal-lg moblie1"
        >
            <Modal.Body>
                <div className="col-md-12">
                    <iframe 
                        width="100%" 
                        height="250" 
                        autoplay={true} 
                        src={`https://www.youtube.com/embed/${urlVideos}?autoplay=1&mute=1`} 
                        title="Patient Story: Alice&#39;s Journey with Common Variable Immunodeficiency (CVID)  | CSL Plasma" 
                        frameborder="0" 
                        allow="autoplay" 
                        allowfullscreen
                    >
                    </iframe>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default VideoModalMobile
