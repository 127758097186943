import React, { useState } from 'react'
import './FooterNavigation.css'
import { FaLinkedinIn, FaTwitter, FaYoutube, FaFacebookF } from 'react-icons/fa'
import AppStore from '../Assets/Images/AppStore.png'
import GooglePlay from '../Assets/Images/GooglePlay.png'
import CSLPlasma from '../Assets/Images/CSL_Logo.png'
import EmailIcons from '../Assets/Desktop/Icons/icon-email.png'
import HelpIcons from '../Assets/Desktop/Icons/help-white.png'
import FooterNavigationMobile from './FooterNavigationMobile'
import SecondaryFooter from './SecondaryFooter'
import '../Assets/Styles/Desktop_Styles/PotentialDonor.css'
import FormModalDesktop from '../Components/Modals/FormModalDesktop'
import { WEB_BASE_URL_LINK, WEB_CSLPLASMA_LINK } from '../config/api'
import SwitchLanguage from '../Components/SwitchLanguage/SwitchLanguage'
import { Translation } from 'react-i18next'
import { validateEmail } from '../utils/validation'

const FooterNavigation = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState()
    const [error, setError] = useState({})
    const [emailValid, setEmailValid] = useState([])

    const HandleModel = () => {
        setModalOpen(!modalOpen)
    }

    const communityData = [
        {
            title: "Plasma P.A.L.S",
            link: WEB_CSLPLASMA_LINK + ""
        },
        {
            title: "Community",
            link: WEB_BASE_URL_LINK + "/community-outreach"
        }
    ]

    const companyData = [
        {
            title: "About Us",
            link: WEB_BASE_URL_LINK + "/about-us"
        },
        {
            title: "Innovation",
            link: WEB_BASE_URL_LINK + "/donation-experience-reimagined"
        },
        {
            title: "Newsroom",
            link: WEB_BASE_URL_LINK + "/newsroom"
        },
    ]

    const helpData = [
        {
            title: "Contact Us",
            link: WEB_BASE_URL_LINK + "/contact-us"
        },
        {
            title: "FAQs",
            link: WEB_BASE_URL_LINK + "/faq"
        }
    ]
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        setError({
            ...error,
            email: event.target.value && !validateEmail(event.target.value) ? "Must be valid email address" : ""
        })
        return false
    }

    return (
        <Translation>
            {t => (
                <div>
                    <footer className='footer-main'>
                        <section className='footer-line d-md-block d-none'>
                        </section>

                        {/* Secondary Footer */}
                        <div className='d-md-block d-none'>
                            <section className='footer_secondary_desktop'>
                                <div className='row section-secondary container'>
                                    <div className='col-lg-6'>
                                        <p className='second_title_desktop'>{t('Help us create awareness. Join Plasma P.A.L.S.!')}</p>
                                        <p className='second_caption_desktop col-lg-12 col'>{t('To find out more visit your CSL Plasma Center or contact us at:')}</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
                                            <img alt='' src={EmailIcons} style={{ width: '25px' }} />
                                            <span style={{ marginLeft: '25px' }} className='font-regular'>
                                                <a href='mailto:plasma.marketing@cslplasma.com' style={{ textDecoration: 'none', color: 'white' }}>plasma.marketing@cslplasma.com</a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-2'>

                                    </div>
                                    <div className='col-lg-4 submit-form mt-5 mt-lg-0 d-flex flex-col justify-content-between'>
                                        <div className='w-100'>
                                            <div className='email_title_desktop mb-3'>
                                                <p className='email_title_desktop'>{t('Email address')}</p>
                                                <div className='tooltip_footer'>
                                                    <img alt='' src={HelpIcons} style={{ width: '25px', height: '25px' }} />
                                                    <span className='tooltiptext'>
                                                        {t("Sign up to receive information about plasma donation and see new donor fees in your area.")}
                                                    </span>
                                                </div>
                                            </div>
                                            <input value={email} onChange={handleChangeEmail} type='email' required className='border-0 w-100' style={{ height: '40px', borderRadius: '3px', outline: 'none', padding: '1rem' }} />
                                        </div>
                                        {
                                            email && !error.email ? <button className='form-button' style={{ marginTop: '25px' }} onClick={() => HandleModel()}>
                                                {t('Submit')}
                                            </button> : <button className='form-button-disabled' style={{ marginTop: '25px' }} >
                                                {t('Submit')}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                        <SecondaryFooter />

                        {/* Primary Footer */}
                        <div className='d-md-block d-none'>
                            <section className='footer-primary'>
                                <div className='row container'>
                                    <div className='col-lg-9'>
                                        <div className='row'>
                                            <ul className='col-lg-3 col-6' style={{ listStyle: 'none' }}>
                                                <li className='mb-3'>
                                                    <p className='footer_title_desktop'>{t('Community')}</p>
                                                </li>
                                                {
                                                    communityData.map((item, index) => (
                                                        <li key={index} className='mb-2'>
                                                            <a href={item.link} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                                <p className='font_navigation_desktop'>{t(item.title)}</p>
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <ul className='col-lg-2 col-6' style={{ listStyle: 'none', paddingLeft: '0px' }}>
                                                <li className='mb-3'>
                                                    <p className='footer_title_desktop'>{t("Company")}</p>
                                                </li>
                                                {
                                                    companyData.map((item, index) => (
                                                        <li key={index} className='mb-2'>
                                                            <a href={item.link} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                                <p className='font_navigation_desktop'>{t(item.title)}</p>
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <ul className='col-lg-3 col-6 mt-lg-0 mt-4 ml-2' style={{ listStyle: 'none', paddingLeft: '50px' }}>
                                                <li className='mb-3'>
                                                    <p className='footer_title_desktop'>{t("Help")}</p>
                                                </li>
                                                {
                                                    helpData.map((item, index) => (
                                                        <li key={index} className='mb-2'>
                                                            <a href={item.link} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                                <p className='font_navigation_desktop'>{t(item.title)}</p>
                                                            </a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            <div className='col-lg-4 col-6 mt-lg-0 mt-4'>
                                                <div className='d-flex flex-row'>
                                                    <a href='https://www.linkedin.com/company/csl-plasma/' rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <FaLinkedinIn size={20} />
                                                    </a>
                                                    <a href='https://twitter.com/CSLPlasma' rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <FaTwitter size={20} style={{ marginRight: '15px', marginLeft: '15px' }} />
                                                    </a>
                                                    <a href='https://www.youtube.com/user/CSLPlasma' rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <FaYoutube size={20} />
                                                    </a>
                                                    <a href='https://www.facebook.com/CSLPlasmaInc/' rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <FaFacebookF size={20} style={{ marginLeft: '15px' }} />
                                                    </a>
                                                </div>
                                                <div className='mt-3' style={{ fontSize: "18px" }}>{t("Download the App")}</div>
                                                <div className='d-flex flex-lg-row flex-col  mt-1'>
                                                    <a href='https://apps.apple.com/us/app/csl-plasma/id1503641894' rel="noopener noreferrer" className='text-decoration-none link-dark h-100 me-lg-2'>
                                                        <img src={AppStore} height="auto" width="100px" alt="appstore_png"></img>
                                                    </a>
                                                    <a href='https://play.google.com/store/apps/details?id=com.cslplasma.cslplasmadonorapplication&hl=en_US&gl=US' rel="noopener noreferrer" className='text-decoration-none link-dark h-100 mt-lg-0 mt-4'>
                                                        <img src={GooglePlay} height="auto" width="100px" alt="googleplay"></img>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2' style={{ display: 'flex' }}>
                                        <a href={WEB_BASE_URL_LINK} rel="noopener noreferrer">
                                            <img width="150px" height="auto" src={CSLPlasma} alt="CSLPlasma" />
                                        </a>
                                    </div>
                                    <div className='col-1 p-0 float-right'>
                                        <SwitchLanguage />
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-12 col-lg-10 order-lg-1 order-2 mt-lg-0 mt-4'>
                                            <div className='d-flex flex-row align-items-center'>
                                                <div className='d-flex aling-items-center'>
                                                    <a href={WEB_BASE_URL_LINK + '/cookie-policy'} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <p className='font_navigation_desktop'>{t("Cookie Policy")}</p>
                                                    </a>
                                                    <span style={{ padding: '0px 10px' }}>|</span>
                                                </div>
                                                <div className='d-flex aling-items-center'>
                                                    <a href={WEB_BASE_URL_LINK + '/privacy-policy'} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <p className='font_navigation_desktop'>{t("Privacy Policy")}</p>
                                                    </a>
                                                    <span style={{ padding: '0px 10px' }}>|</span>
                                                </div>
                                                <div className='d-flex aling-items-center'>
                                                    <a href={WEB_BASE_URL_LINK + '/terms-of-use'} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <p className='font_navigation_desktop'>{t("Terms of Use")}</p>
                                                    </a>
                                                    <span style={{ padding: '0px 10px' }}>|</span>
                                                </div>
                                                <div className='d-flex aling-items-center'>
                                                    <a href={WEB_BASE_URL_LINK + '/accessibility'} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <p className='font_navigation_desktop'>{t("Accessibility")}</p>
                                                    </a>
                                                    <span style={{ padding: '0px 10px' }}>|</span>
                                                </div>
                                                <div>
                                                    <a href={WEB_BASE_URL_LINK + '/sitemap'} rel="noopener noreferrer" className='text-decoration-none link-dark'>
                                                        <p className='font_navigation_desktop'>{t("Sitemap")}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <FooterNavigationMobile />
                    </footer>
                    {/* Modal Email */}
                    <FormModalDesktop modalOpen={modalOpen} HandleModel={HandleModel} emailValue={email}
                        emailValid={emailValid}
                        setEmailValid={setEmailValid}
                        handleEmail={(e) => {
                            setEmail(e)
                        }} setModalOpen={setModalOpen} />
                </div>
            )}
        </Translation>
    )
}

export default FooterNavigation