import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import './index.css';
import './i18n/config'
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LandingPage from './Pages/LandingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route element={<App />}>
        <Route path='/' element={<LandingPage />} />
        <Route path='/:langs/' element={<LandingPage />} />
      </Route>
    </Routes>
  </Router>
);
