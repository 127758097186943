import React, { useState } from 'react'
import Ryan from '../../Assets/Desktop/Meet-Our-Lifesavers-Donors/Ryan.png'
import Nathan from '../../Assets/Desktop/Meet-Our-Lifesavers-Donors/image-nathan.png'
import QuoteRight from '../../Assets/Desktop/Meet-Our-Lifesavers-Employees/quote-mark-left.png'
import QuoteLeft from '../../Assets/Desktop/Meet-Our-Lifesavers-Employees/quote-mark-right.png'
import { Translation } from 'react-i18next'
import VideoModal from '../Modals/VideoModal'
import PlayVideoIcon from '../PlayVideoIcon'

const EmployeeDesktop = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [urlVideos, setUrlVideos] = useState();

  const HandleModel = (url) => {
    setUrlVideos(url);
    setModalOpen(!modalOpen);
  }

  return (
    <Translation>
      {t => (
        <div className='col-md-12 d-md-block d-none' style={{ overflow: 'hidden', marginBottom: '80px' }}>
          <section className='' style={{ width: '100%', height: '15px', backgroundColor: '#03B3BE' }}></section>
          <div className='employees' style={{ backgroundColor: '#FFFFFF' }}>
            <div className='' style={{ paddingTop: '80px' }}>
              <h2>
                {t('Meet our Lifesavers')}<strong className='employeesh6'><br />{t('EMPLOYEES')}</strong>
              </h2>
              <div className='row'>
                <p style={{ marginTop: '28px' }}>{t("Along with our plasma donors, our dedicated team members provide a valuable gift to those who require plasma-derived therapies to live healthier lives. Here’s their take on the impact of donations.")}</p>
              </div>
            </div>
            <div className="container" style={{ marginTop: '125px' }}>
              <div className="row">
                <div className="col-xl-5 col-sm-6">
                  <div className="rounded py-5 px-4">
                    <div className='textContent' >
                      <img src={Ryan} className="img-fluid img" alt="Ryan" style={{ marginLeft: "200px" }} />
                      <PlayVideoIcon position="absolute" marginLeft="220px" marginTopH="70px" HandleModel={() => HandleModel("pTFnrXNqx2o")} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-15px" backgroundColor="black" />
                      <div className="w-100" style={{ height: '30px' }}>
                        <img style={{ width: '30px', marginTop: "40px", marginLeft: '90px' }} className="quote_content" src={QuoteRight} alt="Quote Right" />
                      </div>
                      <span className="textEmployees" style={{ marginLeft: "200px" }}>{t('Saving lives is one thing that you take home. It makes you feel good about your job.')}</span>
                      <div className="w-100 " style={{ height: '30px' }}>
                        <img style={{ width: '30px', marginTop: '-80px', marginLeft: "520px" }} className="quote_content" src={QuoteLeft} alt="Quote Left" />
                      </div>
                      <div style={{marginLeft:"180px", textAlign:'center'}}>
                        <h3 className='header'>Daniel Amor</h3>
                        <p style={{ color: '#4F5556', fontWeight: 500, fontFamily: 'Montserrat', fontSize: '20px' }}>{t('Assistant Center Manager')}</p>
                        <div className=''>
                          <a onClick={() => HandleModel("pTFnrXNqx2o")} className="bootstrap-link" style={{ cursor: "pointer" }}>{t("Watch Daniel’s story")}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-sm-6"></div>
                <div className="col-xl-5 col-sm-6">
                  <div className="rounded py-5 px-4">
                    <div className='textContent' >
                      <img src={Nathan} className="img-fluid img" alt="nathan" style={{marginRight:"200px"}}></img>
                      <PlayVideoIcon position="absolute" marginLeft="-180px" marginTopH="70px" HandleModel={() => HandleModel("QVGh1d34tYM")} size="30" width="65px" height="65px" borderRadius="50%" opacity="0.2" marginLeftBg="-52px" marginTop="-15px" backgroundColor="black" />
                      <div className="w-100" style={{ height: '30px' }}>
                        <img style={{ width: '30px', marginTop: "40px", marginLeft: '-80px' }} className="quote_content" src={QuoteRight} alt="Quote Right" />
                      </div>
                      <span className="textEmployees" style={{marginRight:"200px"}}>{t('I have a niece who uses a plasma product. We almost lost her before they diagnosed her, and now she takes a plasma-derived product, and it saved her life')}</span>
                      <div className="w-100 " style={{ height: '30px' }}>
                        <img style={{ width: '30px', marginLeft: "355px", marginTop: "30px" }} className="quote_content" src={QuoteLeft} alt="Quote Left" />
                      </div>
                     <div style={{marginRight:"180px", textAlign:'center', width:"100%"}}>
                     <h3 className='header'>Michelle Buchanan </h3>
                      <p style={{ color: '#4F5556', fontWeight: 500, fontFamily: 'Montserrat', fontSize: '20px' }}>{t('Learning & Development Manager')}</p>
                      <div className=''>
                        <a onClick={() => HandleModel("QVGh1d34tYM")} className="bootstrap-link" style={{ cursor: "pointer" }}>{t("Watch Michelle’s story")}</a>
                      </div>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VideoModal
            setShow={setModalOpen}
            show={modalOpen}
            HandleModel={HandleModel}
            setModalOpen={setModalOpen}
            urlVideos={urlVideos} />
        </div>
      )}
    </Translation>
  )
}

export default EmployeeDesktop