import React from 'react'
import '../../Assets/Styles/Desktop_Styles/BannerDeskTop.css'
import BannerImage from '../../Assets/Desktop/1. Banner/image.jpg'
import BannerLogo from '../../Assets/Desktop/1. Banner/logo.png'
import { Translation } from 'react-i18next'

const BannerDeskTop = () => {
    return (
        <Translation>
            {t => (
                <div className='banner_desktop d-md-block d-none' style={{ overflow: 'hidden' }}>
                    <div className='banner_image_desktop' style={{
                        backgroundImage: `url(${BannerImage})`
                    }}>
                        <div className='h-50 w-100'>
                            <img alt='BannerLogo' src={BannerLogo} className="banner_logo_desktop m-5 float-end" />
                        </div>
                        <div className='glass_content glassmorphism'>
                            <div className='w-100'>
                                <div className='container'>
                                    <div className='banner_title_desktop row'>
                                        <h1 className='primary_text_banner col-lg-12 col-12'>{t("Do the Amazing with Plasma P.A.L.S.")}</h1>
                                        <div className='col' />
                                    </div>
                                    <div className='banner_description_desktop row'>
                                        <p className='description_text_banner col-lg-12 col-12'>
                                            {t("Plasma P.A.L.S. is a program that helps raise awareness of the importance of plasma donation and the role plasma donors play in creating life-saving therapies. A place for patients and lifesavers to come together.")}
                                        </p>
                                        <div className='col' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Translation>
    )
}

export default BannerDeskTop