import React from 'react'
import CSLPlasma from '../Assets/Images/csl_logo_4.png'
import './DesktopHeader.css'
import { MY_ACCOUNT_URL, WEB_BASE_URL_LINK } from '../config/api'
import { useState } from 'react'
import { MdLocationOn, MdOutlineAccountCircle } from 'react-icons/md'
import { Translation } from 'react-i18next';


const DesktopHeader = () => {
    const [activeMenu, setActiveMenu] = useState()
    const [showDrop, setShowDrop] = useState(false)

    const menus = [
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/start-donating",
            title: "Start Donating",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/online-screening",
                    title: "Online Screening",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/why-plasma",
            title: "Why Plasma?"
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/be-rewarded",
            title: "Be Rewarded",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/igive-rewards",
                    title: "iGive Community",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/careers",
            title: "Careers",
            children: [
                {
                    target: "_blank",
                    link: WEB_BASE_URL_LINK + "/employee-benefits",
                    title: "Employee Benefits",
                    children: []
                }
            ]
        },
        {
            target: "_blank",
            link: WEB_BASE_URL_LINK + "/faq",
            title: "FAQs"
        },
    ]

    return (
        <Translation>
      {t => (
        <div className='desktop_header d-lg-flex d-none'>
            <div className='container d-flex flex-row align-items-center p-0'>
                <a href={WEB_BASE_URL_LINK} rel="noopener noreferrer" className='h-100 d-flex align-items-center mr-1'>
                    <img alt='' src={CSLPlasma} style={{ width: '110px', marginRight: '50px' }} />
                </a>
                <div className='row w-100'>
                    <div className='col-xxl-7 col-12 p-0 d-flex align-items-center'>
                        {
                            menus.map((m, index) => (
                                <div key={index} className="dropdown">
                                    <a
                                        onClick={() => {
                                            if (m.link === "#") {
                                            } else if (m.link === "#referral") {
                                            } else {
                                                setActiveMenu(m.link)
                                                // window.open(m.link)
                                            }
                                        }}
                                        onMouseOver={() => {
                                            setShowDrop(!showDrop)
                                            setActiveMenu(m.link)
                                        }}
                                        href={m.link}
                                        className='navigation_text' style={{ textDecoration: 'none', padding: '9px', color: 'black' }}>
                                        {t(m.title)}
                                    </a>
                                    {
                                        m.children && m.children.length > 0 && (
                                            <ul className={`dropdown-menu ${showDrop && m.link === activeMenu ? "show" : ""}`} show aria-labelledby="dropdownMenuButton1">
                                                {
                                                    m.children && m.children.map((ch, Inch, index) => (
                                                        <li key={index}><a className="dropdown-item" href={ch.link}>{t(ch.title)}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className='col-xxl-5 col-12 p-0 d-flex align-items-center justify-content-xxl-end' style={{ height: '80px' }}>
                        <div className='d-flex flex-row align-items-center'>
                            <div className='d-flex flex-row align-items-center' style={{ marginRight: '15px' }}>
                                <MdLocationOn size={32} style={{ color: 'FC1921' }} />
                                <a href={WEB_BASE_URL_LINK + "/find-a-donation-center"} style={{ fontSize: '18px', color: 'black', textDecoration: 'none' }}>{t("Find Nearest Center")}</a>
                            </div>
                            <div className='d-flex flex-row align-items-center' style={{ marginLeft: '15px' }}>
                                <MdOutlineAccountCircle size={24} style={{ marginRight: '8px' }} />
                                <a href={MY_ACCOUNT_URL} rel="noreferrer" style={{ fontSize: '18px', color: 'black', textDecoration: 'none' }}>{t("My Account/Sign Up")}</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      )}
    </Translation>    
    )
}

export default DesktopHeader