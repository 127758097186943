import React, { Fragment } from 'react'
import DonorDeskTop from './Desktop/DonorDesktop'
import DonorSlideMobile from './Mobile/DonorSlideMobile'

const Donor = () => {
    return (
        <Fragment>
            <DonorDeskTop />
            <DonorSlideMobile />
        </Fragment>
    )
}

export default Donor