import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import { storageKeys } from '../constants/storage'
import * as Storage from '../utils/storage'

const languages = ['en', 'es'];
let url = window.location.pathname;
var langs =  url.substring(url.lastIndexOf('/') + 1)
const lang = languages.includes(langs) ? langs : 'en';

i18n.use(initReactI18next).init({
    fallbackLng: lang,
    lng: lang,
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        es: {
            translations: require('./locales/sp/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
})

i18n.languages = ['en', 'es']

export default i18n;